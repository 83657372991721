import { Home, Briefcase, Users, DollarSign, Activity, UserCheck, Upload } from 'react-feather'

export default [
  {
    id: 'inicio',
    title: 'Inicio',
    icon: <Home size={20} />,
    navLink: '/inicio'
  },
  {
    id: 'clientes',
    title: 'Clientes',
    icon: <Users size={20} />,
    navLink: '/clientes'
  },
  {
    id: 'validacion',
    title: 'Validación',
    icon: <UserCheck size={20} />,
    navLink: '/validacion'
  },
  {
    id: 'empresas',
    title: 'Empresas',
    icon: <Briefcase size={20} />,
    navLink: '/empresas'
  },
  {
    id: 'cobros',
    title: 'Cobros',
    icon: <DollarSign size={20} />,
    navLink: '/cobros'
  },
  {
    id: 'clearing',
    title: 'Clearing',
    icon: <Activity size={20} />,
    navLink: '/clearing'
  }, 
  {
    id: 'pagosDiarios',
    title: 'Pagos Diarios',
    icon: <DollarSign size={20} />,
    navLink: '/pagosDiarios'
  },
  {
    id: 'rendiciones-macro',
    title: 'Rendicion manual',
    icon: <Upload size={20} />,
    navLink: '/rendiciones-macro'
  }
]
