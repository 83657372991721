import { lazy } from 'react'

const isFuncionalidadNoCompleta = () => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const mostrarFuncionalidadCompleta = userData?.usuario?.mostrar_funcionalidad_completa
    return mostrarFuncionalidadCompleta === 0
}

const getLayoutForRoute = (path) => {
    return !isFuncionalidadNoCompleta() ? 'VerticalLayout' : 'BlankLayout'
}

const SameComponentForAllRoutes = lazy(() => import('@src/pages/home/Numeros'))

const All = isFuncionalidadNoCompleta()

const getComponentForRoute = (component) => {
    if (All) {
        return SameComponentForAllRoutes
    }
    return component
}

const Rendiciones = [
    {
        path: '/rendiciones-macro',
        component: getComponentForRoute(lazy(() => import('@src/pages/rendiciones/Rendiciones'))),
        exact: true,
        layout: getLayoutForRoute('/inicio')
    }
]

export default Rendiciones
